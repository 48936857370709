
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("@ember/render-modifiers/modifiers/did-insert", function(){ return i("@ember/render-modifiers/modifiers/did-insert");});
d("@ember/render-modifiers/modifiers/did-update", function(){ return i("@ember/render-modifiers/modifiers/did-update");});
d("@ember/render-modifiers/modifiers/will-destroy", function(){ return i("@ember/render-modifiers/modifiers/will-destroy");});
d("ember-modifier/-private/class/modifier-manager", function(){ return i("ember-modifier/-private/class/modifier-manager");});
d("ember-modifier/-private/class/modifier", function(){ return i("ember-modifier/-private/class/modifier");});
d("ember-modifier/-private/compat", function(){ return i("ember-modifier/-private/compat");});
d("ember-modifier/-private/function-based/modifier-manager", function(){ return i("ember-modifier/-private/function-based/modifier-manager");});
d("ember-modifier/-private/function-based/modifier", function(){ return i("ember-modifier/-private/function-based/modifier");});
d("ember-modifier/-private/interfaces", function(){ return i("ember-modifier/-private/interfaces");});
d("ember-modifier/-private/opaque", function(){ return i("ember-modifier/-private/opaque");});
d("ember-modifier/-private/signature", function(){ return i("ember-modifier/-private/signature");});
d("ember-modifier/index", function(){ return i("ember-modifier/index");});
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "ember-modifier/-embroider-implicit-modules.js";
